import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import toast from "react-hot-toast";
import moment from "moment";
import axios from "axios";
import { Loader, MoreHorizontal, Eye, Edit3, Plus } from "lucide-react";
import { ToastContainer } from "react-toastify";
import BreadCrumb from "Common/BreadCrumb";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import filterDataBySearch from "Common/filterDataBySearch";
import { Search, Download } from "lucide-react";

const FormsList = () => {
  const [formsList, setFormsList] = useState([]);
  const [forms, setForms] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentForm, setCurrentForm] = useState<any>(null);

  const validation = useFormik({
    initialValues: {
      title: "",
      fields: [],
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
    }),
    onSubmit: async (values) => {
      setCreating(true);
      try {
        if (editMode) {
          await axios.put(
            `${process.env.REACT_APP_BASE_URI}/form/${currentForm._id}`,
            {
              form: {
                title: values.title,
                fields: values.fields,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success("Form updated successfully");
        } else {
          await axios.post(
            `${process.env.REACT_APP_BASE_URI}/form`,
            {
              form: {
                title: values.title,
                fields: values.fields,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success("Form created successfully");
        }
        setCreating(false);
        setShow(false);
        handleFetchForms();
      } catch (error) {
        console.log(error);
        setCreating(false);
        toast.error(editMode ? "Error updating Form" : "Error creating Form");
      }
    },
  });

  const toggle = () => {
    setShow(!show);
    if (!show) {
      setEditMode(false);
      setCurrentForm(null);
      validation.resetForm();
    }
  };

  const handleEdit = (form: any) => {
    setCurrentForm(form);
    validation.setValues({
      title: form.title,
      fields: form.fields,
    });
    setEditMode(true);
    setShow(true);
  };

  useEffect(() => {
    handleFetchForms();
  }, []);

  useEffect(() => {
    setForms(formsList);
  }, [formsList]);

  const handleFetchForms = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/form`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setFormsList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteForm = async (id: string) => {
    try {
      setLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/form/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Form deleted successfully");
      handleFetchForms();
    } catch (error: any) {
      setLoading(false);
  
      if (error.response && error.response.data) {
        const { message, steps } = error.response.data;
  
        if (steps && steps.length > 0) {
          const stepDetails = steps.map((step: any) => `${step.stepName} (Program: ${step.programName})`).join(", ");
          toast.error(`${message}: ${stepDetails}`);
        } else {
          toast.error(message);
        }
      } else {
        toast.error("Failed to delete form");
      }
    } finally {
      setLoading(false);
    }
  };
  

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["title", "_id"];
    filterDataBySearch(formsList, search, keysToSearch, setForms);
  };

  const columns = useMemo(
    () => [
      {
        header: "Form ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/form-details/${cell.row.original._id}`}
            className="transition-all duration-150 ease-linear text-black hover:text-black/80 form-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
            </div>
          </div>
        ),
      },
      {
        header: "Number of Fields",
        accessorKey: "fields",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zinc-200">
            {cell.getValue().length}
          </span>
        ),
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zinc-200">
            {moment(cell.row.original.createdAt).format("DD/MM/YYYY - hh:mm A")}
          </span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zinc-600"
              aria-labelledby="orderAction1"
            >
              <li>
                <Link
                  to={`/form-details/${cell.row.original._id}`}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zinc-100 dark:hover:bg-zinc-500 dark:hover:text-zinc-200 dark:focus:bg-zinc-500 dark:focus:text-zinc-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Overview</span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => handleEdit(cell.row.original)}
                  className="block w-full text-left px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zinc-100 dark:hover:bg-zinc-500 dark:hover:text-zinc-200 dark:focus:bg-zinc-500 dark:focus:text-zinc-200"
                >
                  <Edit3 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleDeleteForm(cell.row.original._id)}
                  className="block w-full text-left px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-600 hover:bg-red-100 hover:text-red-500 focus:bg-red-100 focus:text-red-500 dark:text-red-100 dark:hover:bg-red-500 dark:hover:text-red-200 dark:focus:bg-red-500 dark:focus:text-red-200"
                >
                  <span className="align-middle">Delete</span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const handleFieldChange = (index: number, field: string, value: any) => {
    const newFields = [...validation.values.fields];
    // @ts-ignore
    newFields[index][field] = value;
    validation.setFieldValue("fields", newFields);
  };

  const addField = () => {
    validation.setFieldValue("fields", [
      ...validation.values.fields,
      {
        label: "",
        type: "text",
        placeholder: "",
        options: [],
        required: false,
      },
    ]);
  };

  const deleteField = (index: number) => {
    const newFields = [...validation.values.fields];
    newFields.splice(index, 1);
    validation.setFieldValue("fields", newFields);
  };


  return (
    <React.Fragment>
      <BreadCrumb title="Forms" pageTitle="Forms" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zinc-900 dark:bg-opacity-90">
          <Loader className="size-10 text-black animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Forms">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Forms List</h6>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zinc-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zinc-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                      placeholder="Search for title, _id, etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zinc-200 fill-slate-100 dark:fill-zinc-600" />
                  </div>

                  <div className="xl:col-span-3 xl:col-start-10">
                    <div className="flex gap-2 xl:justify-end">
                      <div>
                        <button
                          onClick={toggle}
                          type="button"
                          className="text-white mr-2 btn bg-black border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        >
                          <Plus className="inline-block size-4" /> Add a Form
                        </button>
                        <button
                          type="button"
                          className="bg-white border-dashed text-black btn border-black hover:text-black hover:bg-custom-50 hover:border-black focus:text-black/80 focus:bg-custom-50 focus:border-black active:text-black active:bg-custom-50 active:border-black dark:bg-zinc-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        >
                          <Download className="inline-block size-4" />{" "}
                          <span className="align-middle">Export</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {forms && forms.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={forms || []}
                  customPageSize={50}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zinc-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-black [&.active]:bg-slate-100 dark:[&.active]:bg-zinc-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zinc-200">
                      We've searched more than 199+ forms We did not find any
                      forms for your search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Forms Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="formModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zinc-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zinc-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {editMode ? "Edit Form" : "Add Form"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-14 font-medium text-slate-500 dark:text-zinc-200"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="form-input mt-1 block w-full border-slate-200 dark:border-zinc-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                placeholder="Enter Title"
                {...validation.getFieldProps("title")}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.title}
                </p>
              ) : null}
            </div>

            <div className="mb-4">
              <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                Fields
              </label>
              {validation.values.fields.map((field:any, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg shadow-md relative">
                  <button
                    type="button"
                    onClick={() => deleteField(index)}
                    className="absolute bottom-2 right-2 text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                  <div className="flex gap-4 mb-2">
                    <div className="w-1/3">
                      <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                        Label
                      </label>
                      <input
                        type="text"
                        className="form-input mt-1 block w-full border-slate-200 dark:border-zinc-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        placeholder="Label"
                        value={field.label}
                        onChange={(e) =>
                          handleFieldChange(index, "label", e.target.value)
                        }
                      />
                    </div>
                    <div className="w-1/3">
                      <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                        Type
                      </label>
                      <select
                        className="form-select mt-1 block w-full border-slate-200 dark:border-zinc-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        value={field.type}
                        onChange={(e) =>
                          handleFieldChange(index, "type", e.target.value)
                        }
                      >
                        <option value="text">Text</option>
                        <option value="textarea">Textarea</option>
                        <option value="select">Select</option>
                        <option value="radio">Radio</option>
                        <option value="checkbox">Checkbox</option>
                        <option value="date">Date</option>
                        <option value="pdf">Pdf</option>
                        <option value="kyc">KYC</option>
                      </select>
                    </div>
                    <div className="w-1/3">
                      <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                        Placeholder
                      </label>
                      <input
                        type="text"
                        className="form-input mt-1 block w-full border-slate-200 dark:border-zinc-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        placeholder="Placeholder"
                        value={field.placeholder}
                        onChange={(e) =>
                          handleFieldChange(index, "placeholder", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {["select", "radio", "checkbox"].includes(field.type) && (
                    <div className="mb-4">
                      <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                        Options (comma separated)
                      </label>
                      <input
                        type="text"
                        className="form-input mt-1 block w-full border-slate-200 dark:border-zinc-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zinc-600 disabled:border-slate-300 dark:disabled:border-zinc-500 dark:disabled:text-zinc-200 disabled:text-slate-500 dark:text-zinc-100 dark:bg-zinc-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zinc-200"
                        placeholder="Option1,Option2,Option3"
                        value={field.options.join(",")}
                        onChange={(e) =>
                          handleFieldChange(index, "options", e.target.value.split(","))
                        }
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <label className="block text-14 font-medium text-slate-500 dark:text-zinc-200">
                      Required
                    </label>
                    <input
                      type="checkbox"
                      className="form-checkbox mt-1"
                      checked={field.required}
                      onChange={(e) =>
                        handleFieldChange(index, "required", e.target.checked)
                      }
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addField}
                className="text-white mr-2 btn bg-black border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add Field
              </button>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addForm"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zinc-500 dark:border-zinc-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <AnimationButton
                loading={creating}
                onClick={validation.handleSubmit}
                label={editMode ? "Update Form" : "Add Form"}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default FormsList;
