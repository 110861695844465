import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import TableContainer from "Common/TableContainer";
import { Dropdown } from "Common/Components/Dropdown";
import { Eye, MoreHorizontal } from "lucide-react";
import { ToastContainer } from "react-toastify";
import BreadCrumb from "Common/BreadCrumb";
import { Loader } from "lucide-react";
import { Search } from "lucide-react";

const ClientProgressList = () => {
  const [clientProgressList, setClientProgressList] = useState([]);
  const [clientProgresses, setClientProgresses] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleFetchClientProgresses();
  }, []);

  useEffect(() => {
    setClientProgresses(clientProgressList);
  }, [clientProgressList]);

  const handleFetchClientProgresses = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/client-progress`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setClientProgressList(res.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch client progresses");
    } finally {
      setLoading(false);
    }
  };

  const handleClearProgress = async (id: string) => {
    try {
      setLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/client-progress/${id}/clear`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Client progress cleared successfully");
      handleFetchClientProgresses();
    } catch (error) {
      console.log(error);
      toast.error("Failed to clear client progress");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProgress = async (id: string) => {
    try {
      setLoading(true);
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/client-progress/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Client progress deleted successfully");
      handleFetchClientProgresses();
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete client progress");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Client Name",
        accessorKey: "client.name",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell.getValue()}</span>,
      },
      {
        header: "Program Title",
        accessorKey: "program.title",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell.getValue()}</span>,
      },
      {
        header: "Current Step",
        accessorKey: "currentStep.title",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell.getValue() || "Completed"}</span>,
      },
      {
        header: "Completion Status",
        accessorKey: "completed",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className={cell.getValue() ? "text-green-500" : "text-red-500"}>
            {cell.getValue() ? "Completed" : "In Progress"}
          </span>
        ),
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span>{moment(cell.getValue()).format("DD/MM/YYYY - hh:mm A")}</span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="actionTrigger"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zinc-600"
              aria-labelledby="actionTrigger"
            >
              <li>
                <Link
                  to={`/client-progress-details/${cell.row.original._id}`}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zinc-100 dark:hover:bg-zinc-500 dark:hover:text-zinc-200 dark:focus:bg-zinc-500 dark:focus:text-zinc-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">View Details</span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => handleClearProgress(cell.row.original._id)}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-600 hover:bg-red-100 hover:text-red-500 focus:bg-red-100 focus:text-red-500 dark:text-red-100 dark:hover:bg-red-500 dark:hover:text-red-200 dark:focus:bg-red-500 dark:focus:text-red-200"
                >
                  {/* <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "} */}
                  <span className="align-middle">Clear Progress</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleDeleteProgress(cell.row.original._id)}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-600 hover:bg-red-100 hover:text-red-500 focus:bg-red-100 focus:text-red-500 dark:text-red-100 dark:hover:bg-red-500 dark:hover:text-red-200 dark:focus:bg-red-500 dark:focus:text-red-200"
                >
                  {/* <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "} */}
                  <span className="align-middle">Delete Progress</span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Client Progresses" pageTitle="Client Progresses" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zinc-900 dark:bg-opacity-90">
          <Loader className="size-10 text-black animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="ClientProgresses">
            <div className="card-body">
              {clientProgresses && clientProgresses.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={clientProgresses || []}
                  customPageSize={50}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zinc-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-black [&.active]:bg-slate-100 dark:[&.active]:bg-zinc-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zinc-200">
                      We didn't find any client progresses matching your search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientProgressList;
