import React from "react";
import { Kanban, Users, FileText, ClipboardList, CheckSquare } from "lucide-react";
import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import toast from "react-hot-toast";
import { Loader } from "lucide-react";

const Widgets = () => {
  const [data, setData] = useState({
    totalClients: 0,
    totalPrograms: 0,
    totalForms: 0,
    totalTickets: 0,
    totalClientProgress: 0,
  });

  useEffect(() => {
    handleGetStats();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleGetStats = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/stats/businessStats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { totalClients, totalPrograms, totalForms, totalTickets, totalClientProgress } = response.data;
      setData({
        totalClients,
        totalPrograms,
        totalForms,
        totalTickets,
        totalClientProgress,
      });
    } catch (error: any) {
      if (!error.response) {
        return toast.error("Network error. Please try again.");
      }
      if (typeof error.response.data === "string") {
        return toast.error(error.response.data);
      }
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="order-1 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalClients} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Clients</p>
        </div>
      </div>
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-2 bg-blue-100 dark:bg-blue-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-blue-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-blue-200/50 dark:text-blue-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-blue-500 rounded-md text-15 text-blue-50">
            <FileText />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalPrograms} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Programs</p>
        </div>
      </div>
      <div className="order-3 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-3 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-green-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <ClipboardList />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalForms} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Forms</p>
        </div>
      </div>
      <div className="order-4 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-4 bg-red-100 dark:bg-red-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-red-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-red-200/50 dark:text-red-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-red-500 rounded-md text-15 text-red-50">
            <CheckSquare />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalTickets} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Tickets</p>
        </div>
      </div>
      <div className="order-5 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-5 bg-yellow-100 dark:bg-yellow-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-yellow-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-yellow-200/50 dark:text-yellow-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-yellow-500 rounded-md text-15 text-yellow-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalClientProgress} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Client Progress</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
