import React from "react";

const Preview = ({ fields }:{
    fields: any;
}) => {
  if (!fields || fields.length === 0) {
    return null;
  }

  const renderField = (field:any, index:any) => {
    switch (field.type) {
      case "text":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <input
              type="text"
              placeholder={field.placeholder}
              className="form-input mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              required={field.required}
            />
          </div>
        );
      case "textarea":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <textarea
              placeholder={field.placeholder}
              className="form-textarea mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              required={field.required}
            ></textarea>
          </div>
        );
      case "select":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <select
              className="form-select mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              required={field.required}
            >
              {field.options.map((option:any, i:any) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      case "radio":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            {field.options.map((option:any, i:any) => (
              <div key={i} className="flex items-center mb-2">
                <input
                  type="radio"
                  id={`${field.label}-${option}`}
                  name={field.label}
                  value={option}
                  className="form-radio text-indigo-600 dark:text-indigo-400"
                  required={field.required}
                />
                <label
                  htmlFor={`${field.label}-${option}`}
                  className="ml-2 block text-gray-700 dark:text-gray-300"
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            {field.options.map((option:any, i:number) => (
              <div key={i} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`${field.label}-${option}`}
                  name={field.label}
                  value={option}
                  className="form-checkbox text-indigo-600 dark:text-indigo-400"
                  required={field.required}
                />
                <label
                  htmlFor={`${field.label}-${option}`}
                  className="ml-2 block text-gray-700 dark:text-gray-300"
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      case "date":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <input
              type="date"
              className="form-input mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              required={field.required}
            />
          </div>
        );
      case "time":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <input
              type="time"
              className="form-input mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              required={field.required}
            />
          </div>
        );
      case "image":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <input
              type="file"
              className="form-input mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              accept="image/*"
              required={field.required}
            />
          </div>
        );
      case "pdf":
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">
              {field.label}
            </label>
            <input
              type="file"
              className="form-input mt-1 block w-full bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded py-2 px-3"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              required={field.required}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-xl font-semibold text-gray-800 mb-4 dark:text-gray-200">
        Form Preview
      </h2>
      {fields.map((field:any, index:any) => renderField(field, index))}
    </div>
  );
};

export default Preview;