import React, { useState } from 'react';

export default function Results({ onlineTest }: { onlineTest: any }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const checkAnswer = (questionId: string, answer: string) => {
    const question = onlineTest.questions.find(
      (question: any) => question._id === questionId
    );
    if (!question) return false;
    return question.options[question.answer] === answer;
  };

  const calculateScore = () => {
    let score = 0;
    onlineTest.questions.forEach((question: any) => {
      const answer = onlineTest.answers.find(
        (answer: any) => answer.question === question._id
      );
      if (answer && question.options[question.answer] === answer.answer) {
        score++;
      }
    });
    return score;
  };

  const isPassed = () => {
    const percentageThreshold = onlineTest.passingThreshold;
    const percentageScore =
      (calculateScore() / onlineTest.questions.length) * 100;
    return percentageScore >= percentageThreshold;
  };

  return (
    <div className="font-sans">
      <button
        onClick={() => setIsDialogOpen(true)}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
      >
        View Results
      </button>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-4">Online Test Results</h2>
              <p className="text-gray-600 mb-6">View the results of the test below</p>
              <div className="space-y-6">
                <div className="bg-gray-100 p-4 rounded">
                  <p className="font-semibold">{onlineTest.clientId.name}</p>
                  <p className="text-gray-600">{onlineTest.clientId.email}</p>
                </div>
                <div>
                  <p className="font-semibold mb-2">Answers:</p>
                  {onlineTest.answers.map((answer: any, index: number) => (
                    <div key={answer._id} className="mb-2 last:mb-0">
                      <p className="font-medium">Question {index + 1}:</p>
                      <p
                        className={`${
                          checkAnswer(answer.question, answer.answer)
                            ? 'text-green-600'
                            : 'text-red-600'
                        }`}
                      >
                        {answer.answer}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="bg-gray-100 p-4 rounded">
                  <p className="font-semibold mb-2">Score:</p>
                  <p className="text-2xl font-bold">{calculateScore()} / {onlineTest.questions.length}</p>
                  <p
                    className={`font-semibold ${
                      isPassed() ? 'text-green-600' : 'text-red-600'
                    }`}
                  >
                    {isPassed() ? 'You passed the test' : 'You failed the test'}
                  </p>
                </div>
                <div className="text-sm text-gray-600">
                  <p>Submitted: {onlineTest.isSubmitted ? 'Yes' : 'No'}</p>
                  <p>Created At: {new Date(onlineTest.createdAt).toLocaleString()}</p>
                  <p>Updated At: {new Date(onlineTest.updatedAt).toLocaleString()}</p>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 px-6 py-4 flex justify-end">
              <button
                onClick={() => setIsDialogOpen(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}