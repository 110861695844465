import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Loader } from "lucide-react";
import moment from "moment";
import Preview from "components/ProgramForm/Preview";

const FormDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    handleFetchForm();
  }, [id]);

  const handleFetchForm = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/form/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setForm(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError("Error fetching form details");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90">
        <Loader className="size-10 text-black animate-spin" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <header className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-2 dark:text-gray-200">
          {form?.title}
        </h1>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Created At:</strong>{" "}
          {moment(form?.createdAt).format("DD/MM/YYYY - hh:mm A")}
        </p>
      </header>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 dark:text-gray-200">
          Form Fields
        </h2>
        {form?.fields && form.fields.length > 0 ? (
          <ul className="list-none space-y-4">
            {form.fields.map((field: any, index: any) => (
              <li key={index} className="p-4 bg-white rounded-lg shadow dark:bg-gray-800">
                <p className="text-gray-800 dark:text-gray-200">
                  <strong>Label:</strong> {field.label}
                </p>
                <p className="text-gray-800 dark:text-gray-200">
                  <strong>Type:</strong> {field.type}
                </p>
                {field.placeholder && (
                  <p className="text-gray-800 dark:text-gray-200">
                    <strong>Placeholder:</strong> {field.placeholder}
                  </p>
                )}
                {field.options && field.options.length > 0 && (
                  <p className="text-gray-800 dark:text-gray-200">
                    <strong>Options:</strong> {field.options.join(", ")}
                  </p>
                )}
                <p className="text-gray-800 dark:text-gray-200">
                  <strong>Required:</strong> {field.required ? "Yes" : "No"}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 dark:text-gray-400">No fields found for this form.</p>
        )}
      </div>

      <Preview fields={form?.fields} />
    </div>
  );
};

export default FormDetails;
