import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import BreadCrumb from "Common/BreadCrumb";
import LoadingSpinner from "components/Loader";
import ClientInfo from "components/ClientProgress/ClientInfo";
import StepProgress from "components/ClientProgress/StepProgress";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";

const ClientProgressDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [clientProgress, setClientProgress] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showUpdateStageModal, setShowUpdateStageModal] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchClientProgress = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/client-progress/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setClientProgress(res.data);
      } catch (error) {
        toast.error("Failed to fetch client progress details");
      } finally {
        setLoading(false);
      }
    };

    fetchClientProgress();
  }, [id]);

  const toggleUpdateStageModal = () => {
    setShowUpdateStageModal(!showUpdateStageModal);
  };

  const validation = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URI}/client-progress/${id}/update-stage`,
          values,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Stage updated successfully");
        setShowUpdateStageModal(false);
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/client-progress/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setClientProgress(res.data);
      } catch (error) {
        toast.error("Failed to update stage");
      }
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!clientProgress) {
    return <div>Client progress not found</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <BreadCrumb
        title="Client Progress Details"
        pageTitle="Client Progress Details"
      />
      <ClientInfo
        client={clientProgress.client}
        program={clientProgress.program}
        business={clientProgress.business}
        completed={clientProgress.completed}
        createdAt={clientProgress.createdAt}
      />
      <StepProgress stepsProgress={clientProgress.stepsProgress} />
      {/* Showing the stage info */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">All Stages</h2>
        {clientProgress.stages.map((stage: any) => (
          <div key={stage._id} className="mt-4">
            <h3 className="text-xl font-semibold">{stage.title}</h3>
            <p className="mt-2">{stage.description}</p>
            <p className="mt-2">
              <span className="font-semibold">Created At: </span>
              {new Date(stage.createdAt).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
      <br />
      <button
        onClick={toggleUpdateStageModal}
        className="text-white mr-2 btn bg-black border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20"
      >
        Add a new stage
      </button>

      <Modal
        show={showUpdateStageModal}
        onHide={toggleUpdateStageModal}
        id="updateStageModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">Add a New Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="stageTitleInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Title
              </label>
              <input
                type="text"
                id="stageTitleInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter title"
                name="title"
                onChange={validation.handleChange}
                value={validation.values.title || ""}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-400">{validation.errors.title}</p>
              ) : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="stageDescriptionInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Description
              </label>
              <textarea
                id="stageDescriptionInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter description"
                name="description"
                onChange={validation.handleChange}
                value={validation.values.description || ""}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <p className="text-red-400">{validation.errors.description}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="updateStageModal"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggleUpdateStageModal}
              >
                Cancel
              </button>
              <AnimationButton
                loading={validation.isSubmitting}
                onClick={validation.handleSubmit}
                label="Add a Stage"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClientProgressDetails;
