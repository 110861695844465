import React from "react";
import { Download } from "lucide-react";

interface StepProgressProps {
  stepsProgress: any[];
}

const StepProgress: React.FC<StepProgressProps> = ({ stepsProgress }) => {
  const downloadFile = (url: string, filename: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="mt-8">
      <h3 className="text-2xl font-bold text-gray-900 mb-6">Steps Progress</h3>
      {stepsProgress.map((stepProgress) => (
        <div key={stepProgress.step._id} className="mb-8 p-6 bg-white shadow rounded-lg">
          <h4 className="text-xl font-semibold text-gray-800 mb-4">{stepProgress.step.title}</h4>
          <p className="text-gray-600 mb-4">{stepProgress.step.description}</p>
          <p className="mb-4">
            <strong>Status:</strong>{" "}
            <span className={stepProgress.completed ? "text-green-600" : "text-red-600"}>
              {stepProgress.completed ? "Completed" : "Incomplete"}
            </span>
          </p>
          {stepProgress.formResponses && stepProgress.formResponses.length > 0 && (
            <div className="mt-6">
              <h5 className="text-lg font-semibold text-gray-700 mb-4">Form Responses</h5>
              {stepProgress.formResponses.map((formResponse: any, index: any) => (
                <div key={index} className="mb-4 p-4 border rounded-lg bg-gray-50">
                  {formResponse.response.map((response: any, idx: any) => (
                    <div key={idx} className="mb-2">
                      <strong className="block text-gray-700">{response.title}:</strong>
                      {response.fieldType === "pdf" ? (
                        <button
                          className="flex items-center text-blue-600 hover:text-blue-800"
                          onClick={() => downloadFile(response.value, response.title)}
                        >
                          <Download className="w-4 h-4 mr-2" /> Download {response.title}
                        </button>
                      ) : (
                        <span className="text-gray-800">{response.value}</span>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepProgress;