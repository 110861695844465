import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClientProgramsTabs = ({ clientId }: { clientId: string }) => {
  const [clientPrograms, setClientPrograms] = useState<any[]>([]);
  const [allPrograms, setAllPrograms] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(
    null
  );

  useEffect(() => {
    fetchClientPrograms();
    fetchAllPrograms();
  }, [clientId]);

  const fetchClientPrograms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/program/client/${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setClientPrograms(response.data);
    } catch (error) {
      console.error("Error fetching client programs:", error);
      toast.error("Error fetching client programs");
    } finally {
      setLoading(false);
    }
  };

  const fetchAllPrograms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/program`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAllPrograms(response.data);
    } catch (error) {
      console.error("Error fetching all programs:", error);
      toast.error("Error fetching all programs");
    }
  };

  const handleAssignProgram = async () => {
    if (!selectedProgramId) return;
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URI}/program/assign`,
        { clientId, programId: selectedProgramId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Program assigned successfully");
      fetchClientPrograms();
    } catch (error) {
      console.error("Error assigning program:", error);
      toast.error("Error assigning program");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromProgram = async (programId: string) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/program/client/${clientId}/${programId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Program removed successfully");
      fetchClientPrograms();
    } catch (error) {
      console.error("Error removing program:", error);
      toast.error("Error removing program");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loader className="animate-spin" />
      </div>
    );
  }

  return (
    <div className="p-4">
      <ToastContainer closeButton={false} limit={1} />
      <h2 className="text-2xl font-semibold mb-4">Client Programs</h2>
      <ul className="mb-8">
        {clientPrograms.map((progress) => (
          <li key={progress._id} className="mb-2 flex justify-between">
            <strong>{progress?.program?.title}</strong>
            <button
              disabled={loading}
              onClick={() => handleRemoveFromProgram(progress._id)}
              className="btn bg-red-500 text-white py-1 px-2 rounded shadow hover:bg-red-600"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
      <div className="flex items-center gap-4">
        <select
          className="form-select mt-1 block w-full border-gray-300 rounded py-2 px-3"
          value={selectedProgramId || ""}
          onChange={(e) => setSelectedProgramId(e.target.value)}
        >
          <option value="">Select a program to assign</option>
          {allPrograms.map((program) => (
            <option key={program._id} value={program._id}>
              {program.title}
            </option>
          ))}
        </select>
        <button
          disabled={loading}
          onClick={handleAssignProgram}
          className=" whitespace-nowrap btn bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-600"
        >
          Assign Program
        </button>
      </div>
    </div>
  );
};

export default ClientProgramsTabs;
