import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Loader } from "lucide-react";
import moment from "moment";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Program = () => {
  const { id } = useParams<{ id: string }>();
  const [program, setProgram] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showStepModal, setShowStepModal] = useState<boolean>(false);
  const [creatingStep, setCreatingStep] = useState<boolean>(false);

  const handleFetchProgram = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/program/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProgram(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError("Error fetching program details");
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchProgram();
  }, [id]);

  const toggleStepModal = () => {
    setShowStepModal(!showStepModal);
  };

  const validation = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Step name is required"),
      description: yup.string().required("Step description is required"),
    }),
    onSubmit: async (values) => {
      setCreatingStep(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/step`,
          {
            step: {
              title: values.name,
              description: values.description,
              program: id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCreatingStep(false);
        setShowStepModal(false);
        handleFetchProgram();
        toast.success("Step added successfully");
      } catch (error) {
        console.log(error);
        setCreatingStep(false);
        toast.error("Error adding step");
      }
    },
  });

  if (loading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
        <Loader className="size-10 text-black animate-spin" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <header className="flex items-center mb-6">
        {program?.countryFlag && (
          <img
            src={program.countryFlag}
            alt="country flag"
            className="w-16 h-16 mr-4 rounded-full object-cover"
          />
        )}
        <div>
          <h1 className="text-2xl font-bold">{program?.title}</h1>
          <p className="text-slate-500">{program?.description}</p>
        </div>
      </header>

      <div>
        <h2 className="text-xl font-semibold mb-4">Program Details</h2>
        <p>
          <strong>Title:</strong> {program?.title}
        </p>
        <p>
          <strong>Description:</strong> {program?.description}
        </p>
        <p>
          <strong>Created At:</strong>{" "}
          {moment(program?.createdAt).format("DD/MM/YYYY - hh:mm A")}
        </p>
        <h2 className="text-xl font-semibold mb-4 mt-6">
          Steps{" "}
          <span className="text-sm text-slate-500">
            ({program?.steps.length})
          </span>
        </h2>
        {program?.steps && program.steps.length > 0 ? (
          <ul className="list-disc pl-5">
            {program.steps.map((step: any) => (
              <li 
              className="text-slate-500 dark:text-zink-200 hover:text-black dark:hover:text-white transition-all duration-200 ease-linear cursor-pointer"
              key={step._id}>
                <Link to={`/step-details/${step._id}`}>
                  <strong>{step.title}:</strong> {step.description}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No steps found for this program.</p>
        )}
        <button
          onClick={toggleStepModal}
          className="text-white mt-4 btn bg-black border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        >
          Add a Step
        </button>
      </div>

      {/* Step Modal */}
      <Modal
        show={showStepModal}
        onHide={toggleStepModal}
        id="stepModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Add Step"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-14 font-medium text-slate-500 dark:text-zink-200"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-input mt-1 block w-full border-slate-200 dark:border-zink-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Name"
                {...validation.getFieldProps("name")}
              />
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.name}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-14 font-medium text-slate-500 dark:text-zink-200"
              >
                Description
              </label>
              <textarea
                id="description"
                className="form-input mt-1 block w-full border-slate-200 dark:border-zink-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Description"
                {...validation.getFieldProps("description")}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.description}
                </p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addStep"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggleStepModal}
              >
                Cancel
              </button>
              <AnimationButton
                loading={creatingStep}
                onClick={validation.handleSubmit}
                label="Add Step"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Program;
