import Dashboard from "pages/Dashboard/Analytics";
import Kycs from "pages/KYC/KYCs";
import Support from "pages/Support/Support";
import UsersTable from "pages/Clients/ListView";

import AccountList from "pages/Account/AccountList";
import AccountDetails from "pages/Accounts/AccountDetails";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import SupportDetails from "pages/Support/SupportDetails";
import UserProfile from "pages/UserProfile";
import UserDetails from "pages/Clients/UserDetails";

import Programs from "pages/Programs/ListView";
import Program from "pages/Programs/Program";
import Register from "pages/Authentication/Register";

import FormsList from "pages/Forms/ListView";
import FormDetails from "pages/Forms/Form";

import ClientProgressList from "pages/ClientProgress/ListView";
import ClientProgressDetails from "pages/ClientProgress/ClientProgressDetails";

import FaqsTable from "pages/Faqs/ListView";

import Questions from "pages/questions";
import OnlineTests from "pages/OnlineTest";

import Step from "pages/Steps/Step";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

let authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Dashboard },

  { path: "/dashboard", component: Dashboard },

  // KYC
  { path: "/kycs", component: Kycs },

  // Accounts
  { path: "/clients", component: UsersTable },

  { path: "/accounts", component: AccountList },
  { path: "/account/details/:id", component: AccountDetails },

  // get UserId
  { path: "/user-details/:id", component: UserDetails },

  // Programs
  { path: "/programs", component: Programs },
  { path: "/program-details/:id", component: Program },

  // Support
  { path: "/support", component: Support },
  { path: "/support/:id", component: SupportDetails },

  // Steps
  { path: "/step-details/:id", component: Step },

  // Forms
  { path: "/forms", component: FormsList },
  { path: "/form-details/:id", component: FormDetails },

  // Client Progress
  { path: "/client-progress", component: ClientProgressList },
  { path: "/client-progress-details/:id", component: ClientProgressDetails },

  // Faqs
  { path: "/faqs", component: FaqsTable },

  // profile
  { path: "/user-profile", component: UserProfile },

  // Settings
  { path: "/settings", component: UserProfile },

  // Online Test
  {path: "/questionnaire", component: Questions},
  {path: "/online-test", component: OnlineTests},
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
