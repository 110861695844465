import BreadCrumb from "Common/BreadCrumb";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import toast from "react-hot-toast";
import moment from "moment";
import PhotosUploader from "components/Forms/ImageUploader";

// Icons
import filterDataBySearch from "Common/filterDataBySearch";
import axios from "axios";
import {
  CheckCircle,
  Download,
  Eye,
  Loader,
  MoreHorizontal,
  Search,
  Edit3,
} from "lucide-react";
import { ToastContainer } from "react-toastify";

const Programs = () => {
  const [programsList, setProgramsList] = useState([]);
  const [programs, setPrograms] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentProgram, setCurrentProgram] = useState<any>(null);

  const validation = useFormik({
    initialValues: {
      title: "",
      description: "",
      countryFlag: "",
    },
    validationSchema: yup.object({
      title: yup.string().required("Title is required"),
      description: yup.string().required("Description is required"),
      countryFlag: yup.string().required("Country Flag is required"),
    }),
    onSubmit: async (values) => {
      setCreating(true);
      try {
        if (editMode) {
          await axios.put(
            `${process.env.REACT_APP_BASE_URI}/program/${currentProgram._id}`,
            {
              program: {
                title: values.title,
                description: values.description,
                countryFlag: values.countryFlag,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success("Program updated successfully");
        } else {
          await axios.post(
            `${process.env.REACT_APP_BASE_URI}/program`,
            {
              program: {
                title: values.title,
                description: values.description,
                countryFlag: values.countryFlag,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          toast.success("Program created successfully");
        }
        setCreating(false);
        setShow(false);
        handleFetchPrograms();
      } catch (error) {
        console.log(error);
        setCreating(false);
        toast.error(
          editMode ? "Error updating Program" : "Error creating Program"
        );
      }
    },
  });

  const handleDeleteProgram = async (id: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this program? This action will also delete all clients' progress for this program."
    );

    if (confirmDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URI}/program/business/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Program deleted successfully");
        handleFetchPrograms();
      } catch (error) {
        console.log(error);
        toast.error("Error deleting program");
      }
    } else {
      toast.error("Program not deleted");
    }
  };

  const toggle = () => {
    setShow(!show);
    if (!show) {
      setEditMode(false);
      setCurrentProgram(null);
      validation.resetForm();
      setImages([]);
    }
  };

  const handleEdit = (program: any) => {
    setCurrentProgram(program);
    validation.setValues({
      title: program.title,
      description: program.description,
      countryFlag: program.countryFlag,
    });
    setImages([program.countryFlag]);
    setEditMode(true);
    setShow(true);
  };

  useEffect(() => {
    handleFetchPrograms();
  }, []);

  useEffect(() => {
    setPrograms(programsList);
  }, [programsList]);

  // fetch data
  const handleFetchPrograms = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/program`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setProgramsList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["title", "description", "_id"];
    filterDataBySearch(programsList, search, keysToSearch, setPrograms);
  };

  // columns
  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-black checked:border-black dark:after:text-black dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-black checked:border-black dark:after:text-black dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Program ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/programs-details/${cell.row.original._id}`}
            className="transition-all duration-150 ease-linear text-black hover:text-black/80 programs-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Flag",
        accessorKey: "countryFlag",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <img
            src={cell.getValue()}
            alt="flag"
            className="w-8 h-8 rounded-full object-cover"
          />
        ),
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Description",
        accessorKey: "description",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zink-200">
            {cell.getValue().slice(0, 30)}...
          </span>
        ),
      },
      {
        header: "Creation At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zink-200">
            {moment(cell.row.original.createdAt).format("DD/MM/YYYY - hh:mm A")}
          </span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li>
                <Link
                  to={`/program-details/${cell.row.original._id}`}
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Overview</span>
                </Link>
              </li>
              <li>
                <button
                  onClick={() => handleEdit(cell.row.original)}
                  className="block w-full text-left px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <Edit3 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleDeleteProgram(cell.row.original._id)}
                  className="block w-full text-left px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                >
                  <span className="align-middle">Delete</span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (images.length > 0) {
      validation.setFieldValue("countryFlag", images[0]);
    } else {
      validation.setFieldValue("countryFlag", "");
    }
  }, [images]);

  return (
    <React.Fragment>
      <BreadCrumb title="Programs" pageTitle="Programs" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-black animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Programs">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Programs List</h6>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>

                  <div className="xl:col-span-3 xl:col-start-10">
                    <div className="flex gap-2 xl:justify-end">
                      <div>
                        <button
                          onClick={toggle}
                          // black themed button
                          type="button"
                          className="text-white mr-2 btn bg-black border-black hover:text-white hover:bg-black/80 hover:border-black/80 focus:text-white focus:bg-black/80 focus:border-black/80 focus:ring focus:ring-custom-100 active:text-white active:bg-black/80 active:border-black/80 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        >
                          Add a Program
                        </button>
                        <button
                          type="button"
                          className="bg-white border-dashed text-black btn border-black hover:text-black hover:bg-custom-50 hover:border-black focus:text-black/80 focus:bg-custom-50 focus:border-black active:text-black active:bg-custom-50 active:border-black dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        >
                          <Download className="inline-block size-4" />{" "}
                          <span className="align-middle">Export</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {programs && programs.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={programs || []}
                  customPageSize={50}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-black [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 199+ programs We did not find any
                      programs for you search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Programs Modal */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {editMode ? "Edit Program" : "Add Program"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-14 font-medium text-slate-500 dark:text-zink-200"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="form-input mt-1 block w-full border-slate-200 dark:border-zink-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Title"
                {...validation.getFieldProps("title")}
              />
              {validation.touched.title && validation.errors.title ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.title}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-14 font-medium text-slate-500 dark:text-zink-200"
              >
                Description
              </label>
              <textarea
                id="description"
                className="form-input mt-1 block w-full border-slate-200 dark:border-zink-500 focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter Description"
                {...validation.getFieldProps("description")}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.description}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label
                htmlFor="countryFlag"
                className="block text-14 font-medium text-slate-500 dark:text-zink-200"
              >
                Country Flag
              </label>
              <PhotosUploader
                addedPhotos={images}
                onChange={(photos) => setImages(photos)}
                maxPhotos={1}
              />
              {validation.touched.countryFlag &&
              validation.errors.countryFlag ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.countryFlag}
                </p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <AnimationButton
                loading={creating}
                onClick={validation.handleSubmit}
                label={editMode ? "Update Program" : "Add Program"}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Programs;
