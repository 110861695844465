import withRouter from 'Common/withRouter';
import AnimationButton from 'components/UIElement/UiButtons/AnimationButton';
import { getUserFromLocalStorage, login } from 'helpers/auth';
import AuthIcon from 'pages/AuthenticationInner/AuthIcon';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/useAuthStore';

const Login = () => {
  const navigate = useNavigate();
  document.title = 'Login';

  const { setUser, setLoading, loading } = useAuthStore();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signing, setSigning] = React.useState(false);
  const [remember, setRemember] = React.useState(false);

  const signIn = async () => {
    try {
      setSigning(true);
      if (!email || !password) {
        toast.error('Please fill all fields');
        return;
      }
      const data = await login(email, password);
      setUser(data.user);
      if (remember) {
        localStorage.setItem('token', data.token);
      }

      localStorage.setItem('token', data.token);
      navigate('/');
    } catch (error: any) {
      if (typeof error.response === 'undefined') {
        return toast.error('Network Error');
      }
      if (typeof error.response.data === 'string') {
        return toast.error(error.response.data);
      }
      toast.error('Something went wrong, please try again');
    } finally {
      setSigning(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    handleLoginFromLocalStorage();
  }, []);

  const handleLoginFromLocalStorage = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setLoading(false);
      return;
    }
    try {
      const data = await getUserFromLocalStorage(token);
      setUser(data);
      navigate('/');
    } catch (error) {
      localStorage.setItem('token', '');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      'flex',
      'items-center',
      'justify-center',
      'min-h-screen',
      'py-16',
      'lg:py-10',
      'bg-slate-50',
      'dark:bg-zink-800',
      'dark:text-zink-100',
      'font-public'
    );

    return () => {
      bodyElement.classList.remove(
        'flex',
        'items-center',
        'justify-center',
        'min-h-screen',
        'py-16',
        'lg:py-10',
        'bg-slate-50',
        'dark:bg-zink-800',
        'dark:text-zink-100',
        'font-public'
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative">
        <AuthIcon />
        <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
          <div className="!px-10 !py-12 card-body">
            {/*
           <Link to="/">
              <img
                src={logo}
                alt=""
                className="hidden h-6 mx-auto dark:block"
              />
              <img
                src={logo}
                alt=""
                className="block h-6 mx-auto dark:hidden"
              />
            </Link>
          */}

            <div className="mt-8 text-center">
              <h4 className="mb-1 text-black dark:text-black">
                Welcome Back !
              </h4>
              <p className="text-slate-500 dark:text-zink-200">
                Sign in to your Immigration CRM
              </p>
            </div>

            <form
              className="mt-10"
              id="signInForm"
              onSubmit={async (event: any) => {
                event.preventDefault();
                await signIn();
              }}
            >
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Email
                </label>
                <input
                  type="text"
                  disabled={signing || loading}
                  id="email"
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter username or email"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Password
                </label>
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="password"
                  id="password"
                  disabled={signing || loading}
                  name="password"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-black disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-black placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter password"
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <input
                    id="checkboxDefault1"
                    className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-black checked:border-black dark:checked:bg-black dark:checked:border-black checked:disabled:bg-black checked:disabled:border-black"
                    type="checkbox"
                    value={remember.toString()}
                    onChange={() => {
                      setRemember(!remember);
                    }}
                  />
                  <label
                    htmlFor="checkboxDefault1"
                    className="inline-block text-base font-medium align-middle cursor-pointer"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className="mt-10">
                <AnimationButton
                  className="w-full items-center justify-center"
                  loading={signing || loading}
                  disabled={signing || loading}
                  loadingText={loading ? 'Logging you in...' : 'Signing in'}
                  label="Login"
                  onClick={signIn}
                />
              </div>

              {/* <AnimationButton
                className="w-full items-center justify-center mt-2 font-medium"
                loading={false}
                mode="outlined"
                disabled={false}
                label="Start your free trial"
                onClick={() => {
                  navigate('/register');
                }}
              /> */}
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
