import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Loader } from "lucide-react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Step = () => {
  const { id } = useParams();
  const [stepData, setStepData] = useState<any>(null);
  const [forms, setForms] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);

  useEffect(() => {
    handleFetchStep();
    handleFetchForms();
  }, [id]);

  const handleFetchStep = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/step/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setStepData(response.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError("Error fetching step details");
      setLoading(false);
    }
  };

  const handleFetchForms = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/form`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setForms(response.data);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setError("Error fetching forms");
      setLoading(false);
    }
  };

  const handleAddForm = async () => {
    if (!selectedFormId) return;
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/step/${id}/addForm`,
        {
          formId: selectedFormId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Form added successfully");
      handleFetchStep();
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding form to step");
      setLoading(false);
    }
  };

  const handleRemoveForm = async () => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/step/${id}/removeForm`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Form removed successfully");
      handleFetchStep();
    } catch (error: any) {
      console.log(error);
      toast.error("Error removing form from step");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90">
        <Loader className="size-10 text-black animate-spin" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const { step, program, stepNumber } = stepData || {};

  return (
    <div className="container mx-auto p-4">
      <ToastContainer closeButton={false} limit={1} />
      <header className="mb-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-2 dark:text-gray-200">
          {step?.title}
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {step?.description}
        </p>
        <div className="flex items-center">
          {program?.countryFlag && (
            <img
              src={program.countryFlag}
              alt="country flag"
              className="w-10 h-10 mr-4 rounded-full object-cover"
            />
          )}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
              {program?.title}
            </h2>
            <p className="text-gray-600 dark:text-gray-400">Step {stepNumber}</p>
          </div>
        </div>
      </header>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 dark:text-gray-200">
          Step Details
        </h2>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Title:</strong> {step?.title}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Description:</strong> {step?.description}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Program:</strong> {program?.title}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Step Number:</strong> {stepNumber}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Created At:</strong>{" "}
          {moment(step?.createdAt).format("DD/MM/YYYY - hh:mm A")}
        </p>
        <p className="text-gray-600 dark:text-gray-400">
          <strong>Form:</strong> {step?.form ? step.form.title : "None"}
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4 dark:text-gray-200">
          Manage Forms
        </h2>
        <div className="flex items-center gap-4">
          <select
            className="form-select mt-1 block w-full border-gray-300 dark:border-gray-600 rounded py-2 px-3 dark:bg-gray-700 dark:text-gray-300"
            value={selectedFormId || ""}
            onChange={(e) => setSelectedFormId(e.target.value)}
          >
            <option value="">Select a form to add</option>
            {forms.map((form) => (
              <option key={form._id} value={form._id}>
                {form.title}
              </option>
            ))}
          </select>
          {!step?.form && (
            <button
              onClick={handleAddForm}
              className="btn whitespace-nowrap bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-600 focus:outline-none"
            >
              Add Form
            </button>
          )}
          {step?.form && (
            <button
              onClick={handleRemoveForm}
              className="btn whitespace-nowrap bg-red-500 text-white py-2 px-4 rounded shadow hover:bg-red-600 focus:outline-none"
            >
              Remove Form
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step;
