import React from "react";
import moment from "moment";

interface ClientInfoProps {
  client: any;
  program: any;
  business: any;
  completed: boolean;
  createdAt: string;
}

const ClientInfo: React.FC<ClientInfoProps> = ({ client, program, business, completed, createdAt }) => (
  <div className="bg-white shadow-md rounded p-6 mb-4">
    <h2 className="text-2xl font-semibold mb-4">Client Progress Details</h2>
    <p>
      <strong>Client:</strong> {client.name} ({client.email})
    </p>
    <p>
      <strong>Program:</strong> {program.title}
    </p>
    <p>
      <strong>Business:</strong> {business.name}
    </p>
    <p>
      <strong>Status:</strong>{" "}
      <span className={completed ? "text-green-500" : "text-red-500"}>
        {completed ? "Completed" : "In Progress"}
      </span>
    </p>
    <p>
      <strong>Started At:</strong>{" "}
      {moment(createdAt).format("DD/MM/YYYY - hh:mm A")}
    </p>
  </div>
);

export default ClientInfo;