import { Activity, NotepadText,HelpCircle,Settings, Shield,Archive, Users } from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "Dashboard",
    label: "Dashboard",
    link: "/#",
    icon: <Activity />,
  },
  {
    label: "Clients",
    isTitle: true,
  },
  {
    id: "clients",
    label: "Clients",
    link: "/clients",
    icon: <Users />,
  },
  {
    label: "programs",
    isTitle: true,
  },
  {
    id: "programs",
    label: "Programs",
    link: "/programs",
    icon: <Archive />,
  },
  {
    label: "Clients Progress",
    isTitle: true,
  },
  {
    id: "Clients Progress",
    label: "Clients Progress",
    link: "/client-progress",
    icon: <Archive />,
  },
  {
    label: "Forms",
    isTitle: true,
  },
  {
    id: "forms",
    label: "Forms",
    link: "/forms",
    icon: <NotepadText />,
  },
  {
    label:"Online Test",
    isTitle:true,
  },
  {
    id:"Online Test",
    label:"Online Test",
    link:"/online-test",
    icon:<NotepadText/>
  },
  {
    id:"Questionnaire",
    label:"Questionnaire",
    link:"/questionnaire",
    icon:<NotepadText/>
  },
  {
    label: "KYCs",
    isTitle: true,
  },
  {
    id: "KYCs",
    label: "KYCs",
    link: "/kycs",
    icon: <Shield />,
  },
  {
    label: "Faqs",
    isTitle: true,
  },
  {
    id: "Faqs",
    label: "Faqs",
    link: "/faqs",
    icon: <HelpCircle />,
  },
  {
    label: "Settings",
    isTitle: true,
  },
  {
    id: "Settings",
    label: "Settings",
    link: "/settings",
    icon: <Settings />,
  }
  // {
  //   label: "Support",
  //   isTitle: true,
  // },
  // {
  //   id: "supportTickets",
  //   label: "Support Tickets",
  //   link: "/support",
  //   icon: <AlertTriangle />,
  // },
];

export { menuData };