import axios from "axios";
import PhotosUploader from "components/Forms/ImageUploader";
import { useState } from "react";
import { useAuthStore } from "store/useAuthStore";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";

const BusinessSettings = () => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);

  const [logo, setLogo] = useState([user?.business?.logo]);
  const [name, setName] = useState(user?.business?.name || "");
  const [address, setAddress] = useState(user?.business?.address || "");
  const [phone, setPhone] = useState(user?.business?.phone || "");
  const [website, setWebsite] = useState(user?.business?.website || "");
  const [primaryColor, setPrimaryColor] = useState(
    user?.business?.theme?.colors?.primary || "#000000"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    user?.business?.theme?.colors?.secondary || "#ffffff"
  );
  const [email, setEmail] = useState(user?.business?.smptSettings?.email || "");
  const [appPassword, setAppPassword] = useState(user?.business?.smptSettings?.appPassword || "");

  const handleUpdateBusiness = async () => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/business/update`,
        {
          name,
          address,
          phone,
          website,
          logo: logo[0],
          theme: {
            colors: {
              primary: primaryColor,
              secondary: secondaryColor,
            },
          },
          smptSettings: {
            email,
            appPassword,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-zinc-800 shadow-md rounded-md p-6 mt-6">
      <h4>Update Business Settings</h4>
      <br />
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          Address
        </label>
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">Phone</label>
        <input
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          Website
        </label>
        <input
          type="text"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          Primary Color
        </label>
        <input
          type="color"
          value={primaryColor}
          onChange={(e) => setPrimaryColor(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          Secondary Color
        </label>
        <input
          type="color"
          value={secondaryColor}
          onChange={(e) => setSecondaryColor(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          SMTP Email
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 dark:text-gray-200">
          SMTP App Password
        </label>
        <input
          type="password"
          value={appPassword}
          onChange={(e) => setAppPassword(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <label className="block text-gray-700 dark:text-gray-200">Logo</label>
      <PhotosUploader addedPhotos={logo} maxPhotos={1} onChange={setLogo} />
      <AnimationButton
        onClick={handleUpdateBusiness}
        className="mt-4"
        loading={loading}
        label="Update"
        loadingText="Updating..."
      />
    </div>
  );
};

export default BusinessSettings;
